import { useContext } from "react";
import { IStackTokens, Stack } from "@fluentui/react";
import styles from "./employeeSelection.module.scss";
import AvailableEmployeeCard, { AvailableEmployeeCardActions } from "./AvailableEmployeeCard";
import { Button, Title } from "../common/components";
import { Employee } from "../common/models";
import { LanguageContext, MetadataContext } from "../common/contexts";
import { useNavigate } from "react-router";
import { routes } from "../../constants";
import defaultSettings from "@appSettings/Settings";

export interface EmployeeSelectionProps {
	employees: Employee[];
	onCallNowMeeting: () => void;
	onScheduleMeeting: (employee: Employee) => void;
}

const EmployeeSelection: React.FC<EmployeeSelectionProps> = props => {

	const metadataContext = useContext(MetadataContext);
	const languageContext = useContext(LanguageContext);
	const navigate = useNavigate();

	const boxStackTokens: IStackTokens = { childrenGap: 20 };

	const renderCallNowButton = () => {
		if (!metadataContext.metadata.featureFlags.callNowMeetingsEnabled) {
			return null;
		}

		if (props.employees.findIndex(e => e.isPresentForCalNowMeetings) < 0) {
			return null;
		}

		if (metadataContext.metadata.featureFlags.callNowMeetingsEnabled) {
			return <Button onClick={props.onCallNowMeeting} inverted text={languageContext.messages.bookMeeting.startOnlineMeeting} iconName="VideoSolid" />;
		}

		return null;
	};

	const tryRenderScheduleMeetingWithAnyEmployeeButton = () => {
		const scheduleMeetingsFeatureFlagIsDisabled = metadataContext.metadata.featureFlags.scheduledMeetingsEnabled === false;
		if (scheduleMeetingsFeatureFlagIsDisabled) {
			return null;
		}

		const thereIsNoAvailabilitySlotsPresentForAnyEmployee = props.employees.some(employee => employee.availabilitySlots.length > 0) === false;
		if (thereIsNoAvailabilitySlotsPresentForAnyEmployee) {
			return null;
		}

		if (metadataContext.metadata.featureFlags.callNowMeetingsEnabled) {
			//TODO: OnClick handler, not direct navigate action
			return <Button text={languageContext.messages.bookMeeting.chooseMeetingDate} iconName="Calendar" onClick={() => navigate(routes.scheduleMeetingStartingWithDay)} />;
		}

		return null;
	};

	if (metadataContext.metadata.featureFlags.callCenterEnabled === false) {
		return <Stack horizontalAlign="center">
				<Title large content={languageContext.messages.bookMeeting.callCenterNotEnabled} additionalClassName={styles.title}></Title>;
			</Stack>
	}

	if (props.employees.length == 0) {
		return <Stack horizontalAlign="center">
				<Title large content={languageContext.messages.bookMeeting.noCallCenterEmployees} additionalClassName={styles.title} />
			</Stack>
	}

	return (
		<Stack horizontalAlign="center" tokens={boxStackTokens}>
			<Title additionalClassName={styles.title} large content={languageContext.messages.bookMeeting.header}></Title>
			<Stack horizontal wrap horizontalAlign="center">
				<Title content={languageContext.messages.bookMeeting.subheader1}></Title>
				<Title additionalClassName={styles.titleAccent} content={languageContext.messages.bookMeeting.subheader2}></Title>
			</Stack>
			<Stack wrap tokens={boxStackTokens}>
				{renderCallNowButton()}
				{tryRenderScheduleMeetingWithAnyEmployeeButton()}
			</Stack>
			<Stack horizontal wrap horizontalAlign="center" tokens={boxStackTokens}>
				{props.employees.map((item) => (
					<AvailableEmployeeCard possibleActions={[AvailableEmployeeCardActions.scheduleMeeting]} key={item.id} onScheduleMeeting={props.onScheduleMeeting} employee={item} hidePresence hideSpecialization={defaultSettings.hideSpecialization} />
				))}
			</Stack>
		</Stack>
	);
};

export default EmployeeSelection;
