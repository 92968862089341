import { ITranslation } from "src/modules/common/models/translations";
import * as dateUtils from "../../../utils/dateUtils";
import { liveMeetingEn } from "./liveMeeting.en";

const nbsp: string = "\u00A0";

const en: ITranslation = {
  language: {
    languageCode: "en",
    languageName: "English",
    browserLanguageIsoCodePrefixes: ["en"],
    countrySymbol: "gb",
  },
  messages: {
    calendarStrings: {
        goToToday: "",
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        shortMonths: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dev"
        ],
        days: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ],
        shortDays: [
          "Su",
          "Mo",
          "Tu",
          "We",
          "Th",
          "Fr",
          "Sa"
        ]
      },
    common: {
      defaultAppErrorMessage: "Something went wrong...",
      save: "Save",
      ok: "OK",
      pageNotFound: "Page not found",
    },
    bookMeeting: {
      header: "Find new insurance with ecom Digital Agents",
      subheader1: `Take a free${nbsp}`,
      subheader2: `online advice`,
      callCenterNotEnabled:
        "Call center is currently not available",
      noCallCenterEmployees: "At this moment there is no Agent to assist you",
      personaSecondaryText: "Digital Agent",
      startOnlineMeeting: "Start an online meeting",
      scheduleMeeting: "Schedule a meeting",
      select: "Select",
      selectSchedulingDay: "Day of the meeting",
      selectSchedulingSlot: "Time of the meeting",
      noSlotsAvailable: "No slots available",
      chooseMeetingDate: "Select the date of the meeting",
    },
    footer: {
      companyMark: "ecom software 2022",
      regulations: "Terms of service",
      regulationsLink: "https://ecom.software",
    },
    meetingPage: {
      headerPart1: `Yout free${nbsp}`,
      headerPart2: `online advice`,
      meetingStatus: {
        unknown: "We have no information about this meeting",
        creating: "We're preparing your meeting...",
        readyToStart: "Your meeting is ready",
        inProgress: "Your meeting is ready",
        ended: "Your meeting is over",
        canceled: "Your meeting has been canceled",
      },
      meetingScheduledTo: (date: Date) =>
        `Your meeting is scheduled on ${dateUtils.getTeamsTimeFormat(
          date
        )} ${dateUtils.getTeamsDateFormat(date)}`,
      joinMeetingAllowedFrom: (date: Date) => {
        return `You can join it from ${dateUtils.getTeamsTimeFormat(
          date
        )}`;
      },
      openMeeting: "Open the meeting",
      scheduleAnotherMeeting: "Schedule another meeting",
    },
    meetingDetailsForm: {
      headerPart1: `Your free${nbsp}`,
      headerPart2: `online advice`,
      meetNow: "As soon as possible",
      meetingDataItem: {
        change: "change",
      },
      dayOfTheWeek: (date: Date) => `${dateUtils.toLocalizedWeekday("en", date)},`,
      startMeetingHour: (date: Date) =>
        `at ${dateUtils.toLocalizedHourMinute("en", date)}`,
      firstName: "Name",
      lastName: "Surname",
      email: "E-mail",
      phoneNumber: "Phone number",
      regulationsConsent:
        "I accept the terms of the free online advice service and the rules for the processing of personal data",
      meetingCreation: {
        creatingMeetingIsPending: "Creation of the meeting is in progress...",
        creatingMeetingIsSuccess: "Meeting has been reserved",
        creatingMeetingIsConflict: "The selected Agent is busy now",
        creatingMeetingIsError: "Error occurred when creating the meeting",
        onSuccessSection1: "We've also sent the booking confirmation to your email address.",
        onSuccessSection2: "See you!",
        onConflictSection1: "The selected Agent is unavailable or has another meeting at this time.",
        onConflictSection2: "Please select a different Agent or try again.",
        onErrorSection1: "Error occurred when creating the meeting",
        onErrorSection2: "Please select a different Agent or try again.",
        openMeetingPage: "Open the meeting page",
      },
      createMeeting: "Schedule a meeting",
      validation: {
        wrongEmail: "The email address provided is invalid",
        wrongPhoneNumber: "The provided phone number is not valid",
      },
      backToMain: "Back to the home page",
    },
    availabilityCalendar: {
        chooseMeetingTime: "Choose meeting time",
        chosenAgentHasNoSlotsAvailable: "Chosen Agent has no schedule available",
        tryDifferentAgent: "Please select a different Agent or try again"
      },
      liveMeeting: liveMeetingEn
  },
};

export default en;