import { ISettings } from "../ISettings";

const defaultSettings: ISettings = {
    baseIconsUrl: "https://res.cdn.office.net/files/fabric-cdn-prod_20241209.001/assets/icons/",
    logoFileName: "logo.png",
    publicFolder: "/images/vanilla",
    baseApiUrl: "",
    applicationInsights: {
        cloudRoleName: "Customers",
        connectionString : ""
    }
}

export default defaultSettings;