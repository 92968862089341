import { useEffect, useContext, useState } from "react";
import { initializeIcons } from "@fluentui/react";
import styles from "./app.module.scss";
import { MetadataContext, GlobalContext, QueryParamsContext } from "./common/contexts";
import GlobalLoader from "../modules/global/loader/GlobalLoader";
import defaultSettings from "@appSettings/Settings";

export const App: React.FC = props => {

	const globalContext = useContext(GlobalContext);
	const metadataContext = useContext(MetadataContext);
	const queryParamsContext = useContext(QueryParamsContext);

	const [firstTimeLoading, setFirstTimeLoading] = useState<boolean>(true);

	useEffect(() => {
		queryParamsContext.setQueryContext();
		const getMetadata = async () => {
			await metadataContext.fetchMetadata();
			setFirstTimeLoading(false);
		};

		initializeIcons(defaultSettings.baseIconsUrl);
		getMetadata();
	}, []);

	if (firstTimeLoading) {
		return <GlobalLoader visible={true} />;
	}

	const getBackgroundImageStyle = () => {
		if (defaultSettings.appBackgroundFileName) {
			const path = `url(${defaultSettings.publicFolder}/${defaultSettings.appBackgroundFileName})`;

			if (defaultSettings.appBackgroundStyle) {
				return defaultSettings.appBackgroundStyle;
			}

			return {
				backgroundImage: path,
				backgroundRepeatX: 'no-repeat',
				backgroundPositionX: 'center',
				minHeight: '100vh',
			}
		}
		return undefined;
	}

	return (
		<GlobalLoader visible={globalContext.appLoading} loadingMessage={globalContext.appLoadingMessage}>
			<div className={styles.app} style={getBackgroundImageStyle()}>
				{props.children}
			</div>
		</GlobalLoader>
	);
};

export default App;
